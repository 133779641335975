.nocontent-index {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: (125rem / 100);

    .nocontent-index-null {
        width: (218rem / 100);
        height: (286rem / 100);
        margin-bottom: (24rem / 100);
    }

    .nocontent-index-msg {
        font-size: (16rem / 100);
        font-family: PingFangSC-Regular, PingFang SC;
        color: #273143;
        line-height: (16rem / 100);
    }
}